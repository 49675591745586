import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { background, space } from 'styled-system'

const Wrapper = styled.div`
  ${background};
  ${space};
  position: relative;
  overflow: hidden;
  &:before {
    display: block;
    content: '';
    width: 100%;
    padding-top: calc(${p => p.height / p.width} * 100%);
  }
`

const Content = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

const AspectRatio = ({ children, ...props }) => (
  <Wrapper {...props}>
    <Content>{children}</Content>
  </Wrapper>
)

AspectRatio.propTypes = {
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  children: PropTypes.node,
  background: PropTypes.string,
}

AspectRatio.defaultProps = {
  children: null,
  background: '',
}

export default AspectRatio
