import React, { useEffect } from 'react';
import uniqBy from 'lodash/uniqBy';
import MembersLayout from '../../components/Members/Layout';
import Container from '../../components/Container';
import MembersHero from '../../components/Members/Hero';
import GateKeeper from '../../components/FirebaseAuth/GateKeeper';
import { Box } from 'rebass';
import MembersCategoryList from '../../components/Members/Category/List';
import styled from 'styled-components';
import bg from './bg.svg';
import { height } from 'styled-system';
import useBbshMember from '../../utils/hooks/members/useBbshMember';
import MembersCategoryCard from '../../components/Members/Category/Card';
import MembersSearchDocument from '../../components/Members/Search';
import qs from 'qs';
import usePrismic from '../../utils/hooks/members/usePrismic';
import { getUserCategories } from '../../components/Members/queries';
import EmailVerification from '../../components/FirebaseAuth/EmailVerification';
import { useFirebaseAuth } from '../../components/FirebaseAuth';
import SEO from '../../components/seo';

const Wrapper = styled.div`
  position: relative;
  /* z-index: 3; */
`;

const Background = styled.div`
  background: url(${bg});
  background-repeat: no-repeat;
  background-size: cover;
  background-position-y: 100%;
  width: 100%;
  ${height}
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
`;

Background.defaultProps = {
  height: ['260px'],
};

const MemberPortal = ({ location, navigate }) => {
  const { user } = useFirebaseAuth();
  const { search } = qs.parse(location.search, { ignoreQueryPrefix: true });
  const didSearch = search;
  const setDidSearch = value => {
    navigate(`${location.pathname}?${qs.stringify({ search: value })}`, {
      replace: true,
    });
  };
  const [fetch, { data, isLoading }] = usePrismic({
    query: getUserCategories,
  });
  useEffect(() => {
    fetch();
  }, []);
  const { member } = useBbshMember();
  const categories = uniqBy(
    data?.allTabs?.edges?.filter(({ node }) => Boolean(node.category)) || [],
    ({ node }) => node?.category?._meta?.id,
  );

  return (
    <GateKeeper>
      <SEO title="Student Portal" />
      <MembersLayout transparentHeader>
        <Container>
          <EmailVerification user={user} />
          {!didSearch && <Background />}
          <Wrapper>
            {!didSearch && (
              <MembersHero
                title={`Hi ${member?.firstName || ''}!`}
                description="Welcome to the BBSH Student Portal. Use the categories below to browse through our educational files and documents"
              />
            )}
            <MembersSearchDocument
              didSearch={didSearch}
              onSearch={setDidSearch}
              my={didSearch ? 0 : [3, 4]}
            />
            {!didSearch && (
              <Box mt={[4, 5]}>
                {isLoading ? (
                  <Box p={3} m={-3} width={[1 / 2, 1 / 4, 1 / 6]}>
                    <MembersCategoryCard isLoading />
                  </Box>
                ) : (
                  <MembersCategoryList
                    edges={categories}
                    libraryPage={data?.allLibrary_pages?.edges[0]}
                  />
                )}
              </Box>
            )}
          </Wrapper>
        </Container>
      </MembersLayout>
    </GateKeeper>
  );
};

MemberPortal.propTypes = {};

export default MemberPortal;
