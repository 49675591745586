import React from 'react';
import styled from 'styled-components';
import Text from '../../Text';

const Wrapper = styled.div`
  max-width: 480px;
`;

const MembersHero = ({ title, description }) => {
  return (
    <Wrapper>
      {title && (
        <Text
          color="white"
          as="h1"
          fontSize={(4, 5)}
          mb={description ? 1 : undefined}
        >
          {title}
        </Text>
      )}
      {description && <Text variant="tertiary">{description}</Text>}
    </Wrapper>
  );
};

MembersHero.propTypes = {};

export default MembersHero;
