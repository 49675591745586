import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Box } from 'rebass';
import { Link } from 'gatsby';
import MembersCategoryCard from '../Card';

const MembersCategoryList = ({ edges, libraryPage, ...props }) => {
  return (
    <Flex m={-3} flexWrap="wrap" {...props}>
      {edges.map(({ node }) => {
        const id = node?.category?._meta.id;
        return (
          <Box p={3} width={[1 / 2, 1 / 4, 1 / 6]} key={id}>
            <Link to={`/members/category?categoryId=${id}`}>
              <MembersCategoryCard
                icon={node?.category?.icon?.url}
                title={node?.category?.title[0]?.text}
              />
            </Link>
          </Box>
        );
      })}
      {libraryPage && (
        <Box width={[1 / 2, 1 / 4, 1 / 6]} p={3}>
          <Link to={`/members/library`}>
            <MembersCategoryCard
              title={libraryPage?.node?.title[0]?.text}
              icon={libraryPage?.node?.icon?.url}
            />
          </Link>
        </Box>
      )}
    </Flex>
  );
};

MembersCategoryList.propTypes = {
  edges: PropTypes.arrayOf(PropTypes.object),
};

export default MembersCategoryList;
