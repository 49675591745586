import React from 'react';
import styled from 'styled-components';
import { RichText } from 'prismic-reactjs';
import Card from '../../../Card';
import Text from '../../../Text';
import { FiFileText, FiPlayCircle } from 'react-icons/fi';
import { Flex, Box } from 'rebass';
import Html from '../../../Layout/Html';
import { Link } from 'gatsby';
import Hr from '../../../Layout/Hr';

export const DocumentCard = styled(Card)`
  border-radius: 8px;
  /* display: flex; */
  /* align-items: center; */
  /* justify-content: space-between; */
  box-shadow: 0 0 24px 0 rgba(0, 0, 0, 0.08);
  transition: 0.1s;
  height: 100%;
  display: flex;
  flex-direction: column;
  &:hover {
    box-shadow: 0 0 32px 0 rgba(0, 0, 0, 0.16);
  }
`;

const Inner = styled(Box).attrs({ p: [3, null, 4] })``;

const ContentsText = styled(Text).attrs({ variant: 'secondary' })`
  display: flex;
  align-items: center;
`;

const getFileFormatCounts = files => {
  let formatCounts = {};
  files?.forEach(({ file }) => {
    const format = file?.url?.split('.').pop();
    if (format) {
      formatCounts[format] = (formatCounts[format] || 0) + 1;
    }
  });
  return formatCounts;
};

const BbshDocument = ({
  _meta,
  title,
  description,
  embeds,
  files,
  goBack,
  ...props
}) => {
  const fileTitle = title?.[0]?.text;
  const hasContents = files?.length || embeds?.length;
  const formatCounts = getFileFormatCounts(files);
  return (
    <Link
      to={`/members/document?documentId=${_meta?.id}${
        goBack ? `&goBack=${goBack}` : ''
      }`}
    >
      <DocumentCard {...props}>
        <Inner flex={1}>
          <Text fontSize={[2, 3]} fontWeight="500">
            {fileTitle}
          </Text>
          {description && (
            <Html>
              <RichText render={description} />
            </Html>
          )}
        </Inner>
        {hasContents && (
          <>
            <Hr width={1} height="2px" />
            <Inner>
              <Flex m={-2}>
                {!!files?.length && (
                  <ContentsText p={2}>
                    <FiFileText />
                    &nbsp;
                    {Object.keys(formatCounts)
                      .map(
                        format =>
                          `${formatCounts[format]} ${format.toUpperCase()}`,
                      )
                      .join(' • ')}
                    {/* &nbsp;{files?.length} File{files?.length > 1 && 's'} */}
                  </ContentsText>
                )}
                {!!embeds?.length && (
                  <ContentsText p={2}>
                    <FiPlayCircle />
                    &nbsp;{embeds?.length} Media
                  </ContentsText>
                )}
              </Flex>
            </Inner>
          </>
        )}
      </DocumentCard>
    </Link>
  );
};

export default BbshDocument;
