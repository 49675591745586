import React from 'react';
import ContentLoader from 'react-content-loader';

const CategoryCardLoadingState = props => {
  return (
    <>
      <ContentLoader {...props}>
        <circle cx="16" cy="16" r="16" />
      </ContentLoader>
      <ContentLoader
        speed={2}
        width={128}
        height={24}
        viewBox="0 0 128 24"
        backgroundColor="#f3f3f3"
        foregroundColor="#ecebeb"
      >
        <rect x="0" y="0" rx="4" ry="4" width="128" height="24" />
      </ContentLoader>
    </>
  );
};

CategoryCardLoadingState.defaultProps = {
  speed: 2,
  width: 72,
  height: 72,
  viewBox: '0 0 72 72',
  backgroundColor: '#f3f3f3',
  foregroundColor: '#ecebeb',
};

export default CategoryCardLoadingState;
