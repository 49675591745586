import React, { useState } from 'react';
import Card from '../Card';
import Text from '../Text';
import styled from 'styled-components';
import Container from '../Container';

const Wrapper = styled.div`
  position: fixed;
  bottom: 16px;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);
`;

const EmailVerification = ({ user, ...props }) => {
  const [isLoading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const onSend = () => {
    setLoading(true);
    user
      .sendEmailVerification()
      .then(() => {
        setLoading(false);
        setSuccess(true);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  if (user && !user.emailVerified) {
    return (
      <Wrapper>
        <Container>
          <Card p={3} {...props}>
            <Text color="coralRed">
              Your email address is still pending verification. Please click on
              the link that you{`'`}ve received via email to do so. Didn{`'`}t
              get it yet? Please, check your spam folder or have us{' '}
              <a href="#!" onClick={onSend}>
                send it again
              </a>
              .
            </Text>
            {success && <Text>Email sent.</Text>}
            {isLoading && <Text>Sending.</Text>}
          </Card>
        </Container>
      </Wrapper>
    );
  }
  return null;
};

EmailVerification.propTypes = {};

export default EmailVerification;
