import React, { useState } from 'react';
import Input from '../../Input';
import { Box, Flex } from 'rebass';
import BbshDocument from '../BbshDocument/Card';
import styled, { css } from 'styled-components';
import Container from '../../Container';
import { FiArrowLeft, FiSearch } from 'react-icons/fi';
import Spinner from '../../Spinner';

import EmptySlate from '../EmptySlate';
import Alert from '../../Alert';
import Button from '../../Button';
import usePrismic from '../../../utils/hooks/members/usePrismic';
import { getBbshDocument } from '../queries';

const Wrapper = styled(Box)`
  ${({ didSearch }) =>
    didSearch &&
    css`
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      display: flex;
      flex-direction: column;
    `}
`;

const Results = styled(Box)`
  height: 100%;
  overflow-y: auto;
`;

const Header = styled(Box)``;

const InnerHeader = styled(Flex)`
  align-items: center;
`;

const MembersSearchDocument = ({ didSearch, onSearch, ...props }) => {
  const [value, onChange] = useState('');
  const [fetch, { data, isLoading, error }] = usePrismic({
    query: getBbshDocument,
  });
  const _fetch = options => {
    fetch({ query: getBbshDocument, variables: options });
    onSearch(true);
  };
  const noResults = !isLoading && data?.allBbsh_documents?.edges?.length === 0;
  return (
    <Wrapper didSearch={didSearch} {...props}>
      <Header
        py={didSearch ? [2] : undefined}
        bg={didSearch ? 'white' : undefined}
      >
        <Container
          mx={!didSearch ? 0 : undefined}
          px={!didSearch ? 0 : undefined}
        >
          <InnerHeader
            as="form"
            onSubmit={e => {
              e.preventDefault();
              if (value) _fetch({ fulltext: value });
            }}
          >
            {didSearch && (
              <FiArrowLeft size={24} onClick={() => onSearch(undefined)} />
            )}
            <Input
              ml={didSearch ? 2 : 0}
              placeholder="Search"
              value={value}
              background={didSearch ? undefined : 'white'}
              width={didSearch ? 1 : [1, null, 1 / 3]}
              onChange={e => onChange(e.target.value)}
              style={{ transition: '0.2s ease-in' }}
            />
            <Button
              variant="white"
              type="submit"
              ml={2}
              css={{
                padding: 0,
                width: 48,
                height: 48,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <FiSearch size={24} />
            </Button>
          </InnerHeader>
        </Container>
      </Header>
      {didSearch && (
        <Results>
          <Container py={[3, 4]}>
            {/*  eslint-disable react/display-name */}
            {!isLoading &&
              data?.allBbsh_documents?.edges?.map(({ node }, idx, arr) => (
                <BbshDocument
                  key={node?._meta?.id}
                  mb={idx === arr.length - 1 ? undefined : 3}
                  {...node}
                />
              ))}
            {isLoading && <Spinner display="block" mx="auto" />}
            {noResults && <EmptySlate />}
            {error && (
              <Alert type="danger">
                {error ||
                  error.message ||
                  'Sorry, there was an unexpected error'}
              </Alert>
            )}
          </Container>
        </Results>
      )}
    </Wrapper>
  );
};

export default MembersSearchDocument;
