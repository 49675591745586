import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { space, lineHeight, width, flex, borderColor } from 'styled-system';
import Text from '../Text';

const StyledInput = styled.input.attrs(({ inline }) => ({
  px: inline ? 0 : 4,
  py: inline ? 0 : 3,
  pb: inline ? 2 : 3,
}))`
  ${width}
  ${space}
  ${lineHeight}
  ${flex}
  background: ${({ theme, variant }) =>
    variant === 'secondary' ? theme.grays['100'] : 'white'};
  border-radius: 100px;
  border: 0;
  ${({ inline, theme }) =>
    inline &&
    css`
      background: none;
      border-bottom: 2px solid ${theme.grays['200']};
      border-radius: 0;
    `}
  ${borderColor};
  &:disabled{
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Label = styled(Text).attrs(() => ({
  as: 'label',
  variant: 'secondary',
  mb: 3,
}))`
  display: block;
`;

const Input = ({ label, labelFontSize, inline, error, touched, ...props }) => {
  const ref = useRef();
  if (label) {
    return (
      <span>
        <Label htmlFor={ref.current} fontSize={labelFontSize}>
          {label}
        </Label>
        <StyledInput
          borderColor={
            touched ? (error ? 'coralRed' : 'mountainMeadow') : undefined
          }
          inline={inline}
          ref={ref}
          {...props}
        />
        {touched && error && (
          <Text fontSize={0} width={1} color="coralRed">
            {error}
          </Text>
        )}
      </span>
    );
  }
  return (
    <StyledInput
      borderColor={
        touched ? (error ? 'coralRed' : 'mountainMeadow') : undefined
      }
      inline={inline}
      ref={ref}
      {...props}
    />
  );
};

Input.defaultProps = {
  px: 4,
  py: 3,
};

export default Input;
