import React from 'react';
import PropTypes from 'prop-types';
import { space } from 'styled-system';
import Card from '../../../Card';
import Text from '../../../Text';
import styled from 'styled-components';
import AspectRatio from '../../../AspectRatio';
import CategoryCardLoadingState from './LoadingState';

const Icon = styled.img`
  ${space}
`;

const StyledCard = styled(Card)`
  box-shadow: 0px 0px 32px 0px rgba(0, 0, 0, 0.16);
  transition: box-shadow 0.2s;
  &:hover {
    box-shadow: 0px 0px 40px 0px rgba(0, 0, 0, 0.24);
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const MembersCategoryCard = ({ icon, title, isLoading, ...props }) => {
  return (
    <StyledCard p={[3, 4]} {...props}>
      <AspectRatio width={1} height={1}>
        <InnerWrapper>
          {isLoading ? (
            <CategoryCardLoadingState />
          ) : (
            <>
              <Icon src={icon} mb={4} />
              <Text
                fontSize={[2, 3]}
                fontWeight="500"
                width={1}
                style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}
              >
                {title}
              </Text>
            </>
          )}
        </InnerWrapper>
      </AspectRatio>
    </StyledCard>
  );
};

MembersCategoryCard.propTypes = {
  node: PropTypes.object.isRequired,
};

export default MembersCategoryCard;
